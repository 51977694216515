<template>
  <v-card class="ma-2 pa-2">
    <v-layout class="pb-4">
      <v-btn tile outlined color="primary" @click="$router.push(`/exam`)"><v-icon>mdi-arrow-left</v-icon></v-btn>
        <v-card-title class="py-0"> {{examObj._id ? 'Update Exam' : 'Create Exam'}} </v-card-title>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="SaveUpdateHandler" tile> {{ examObj._id ? 'Update' : 'Save'}} </v-btn>
    </v-layout>
    <v-divider class="py-2"></v-divider>
    <v-form ref="form" v-model="valid">
      <v-row class="pb-5">
        <v-col cols="12" lg="4" md="4" sm="3">
          <v-text-field outlined dense hide-details label="Title" v-model="examObj.title" :rules="setValidation('requiredValidation')"></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="3">
          <v-text-field single-line outlined dense hide-details label="Description" v-model="examObj.description"></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="3">
          <v-select v-model="examObj.class" :items="listOfClassrooms" outlined dense label="Classroom" item-text="name" item-value="_id"></v-select>
        </v-col>
      </v-row>
      <v-layout v-if="!examObj._id">
        <v-flex align-self-end>
          <span class="font-weight-black"> Subject Info:  </span>
        </v-flex>
        <v-spacer></v-spacer>
        <v-checkbox hide-details label="Create Multiple Exams"></v-checkbox>
      </v-layout>
      <v-divider class="my-1 mx-4"></v-divider>
       <v-simple-table>
        <thead>
          <tr>
            <td class="text-center font-weight-black">Subject</td>
            <td class="text-center font-weight-black">Total Mark</td>
            <td class="text-center font-weight-black">Pass Mark</td>
            <td class="text-center font-weight-black">Exam Date</td>
            <td class="text-center font-weight-black">Exam Time</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, index) in examObj.subjects" :key="index">
            <td class="px-2">
              <v-autocomplete hide-details v-model="i.subject" :items="listOfSubjects" outlined dense item-text="name" item-value="_id"></v-autocomplete>
            </td>
            <td class="px-2">
              <v-text-field outlined dense hide-details v-model="i.totalMark" :rules="setValidation('requiredValidation')"></v-text-field>
            </td>
            <td class="px-2">
              <v-text-field outlined dense hide-details v-model="i.passMark" :rules="setValidation('requiredValidation')"></v-text-field>
            </td>
            <td class="px-2">
              <v-menu v-model="i.fromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field hide-details :value="$_formatDate(i.date, 'YYYY-MM-DD', 'DD.MM.YYYY')"
                  prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
                </template>
                <v-date-picker v-model="i.date" @input="i.fromMenu = false"></v-date-picker>
              </v-menu>
            </td>
            <td class="px-2">
              <v-menu :ref="`startMenu_${index}`" v-model="i.menu2" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="mb-1" outlined placeholder="from" v-model="i.starttime" prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" hide-details dense></v-text-field>
                </template>
                <v-time-picker format="24hr" v-if="i.menu2" v-model="i.starttime" @click:minute="$refs[`startMenu_${index}`][0].save(i.starttime)" full-width></v-time-picker> 
              </v-menu>
            </td>
            <td>
              <template v-if="!examObj._id">
                <v-btn v-if="index === 0" fab x-small color="primary" @click="examObj.subjects.push({ type: null })"><v-icon dark> mdi-plus </v-icon></v-btn>
                <v-btn v-else fab small color="error" @click="examObj.subjects.splice(index, 1)" icon><v-icon dark> mdi-delete </v-icon></v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      valid: false,
      examObj: {
        data: {},
        subjects: [
          {}
        ]
      },
      listOfClassrooms: [],
      dialog: false,
      getlistOfSubjects: [],
    }
  },
  computed: {
    ...mapState(['listOfSubjects'])
  },
  mounted () {
    this.$store.dispatch('getSubjects').then((subjects) => {
       this.getlistOfSubjects = subjects
      if (this.$route.params.id) this.getSingleRecordHandler(this.$route.params.id)
    })
  },
  components: {
    // 
  },
  methods: {
    SaveUpdateHandler() {
      let url = this.examObj._id ? `exams/${this.$route.params.id}` : `exams/bulkSaveOrEdit`
      let method = this.examObj._id ? `put` : `post`
      const model = []
      this.examObj.subjects.forEach(x => {
        model.push({ ...this.examObj, ...x })
      })
      this.$_execute(method, url, method === 'post' ? model : model[0]).then(() => {
        this.$root.$emit('snackbar', { message: this.examObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
        this.$router.push(`/exam`)
      })
    },
    getSingleRecordHandler (id) {
      this.$_execute('get', `exams/${id}`).then(response => {
        this.examObj = response.data
        this.examObj.subjects = [ {...this.examObj} || {} ]
      })
    }
  }
}
</script>

<style>

</style>